export const franchisePermalinks: string[] = [
  '/marketing/folders',
  '/marketing/flyers',
  '/business-cards',
  '/boxes/shipping-boxes',
  '/landing/booklets',
  '/marketing/postcards',
  '/signage/banners',
  '/marketing/brochures',
];

export const insightsPermalinks: string[] = [
  '/marketing/rack-cards',
  '/copies/color-copies',
  '/business-cards',
  '/landing/booklets',
  '/marketing/postcards',
  '/signage/banners',
  '/boxes/shipping-boxes',
  '/stickers',
];

export const insightsNames: string[] = [
  'Copies',
  'Posters and Banners',
  'Business Cards',
  'Postcards',
  'Booklets',
  'Stickers',
  'Marketing',
  'Shipping Boxes',
];

export const insightsLinks: string[] = [
  '/copies',
  '/signage',
  '/business-cards',
  '/marketing/postcards',
  '/booklets',
  '/stickers',
  '/marketing',
  '/boxes',
];
