import * as React from 'react';

const DiscountToolTipContent = () => (
  <div>
    <p className="paragraph-desktop">
      <strong>
        How to Redeem
        <br />
      </strong>
    </p>
    <ol className="ml-5">
      <li>Register an account.</li>
      <li>Log into your account.</li>
      <li>Enter the promo code into your shopping cart.</li>
    </ol>
    <p className="paragraph-desktop">
      <strong>
        Terms &amp; Conditions
        <br />
      </strong>
    </p>
    <ul className="list-inside">
      <li>Must have never placed an order with Printivity, formerly MGX Copy.</li>
      <li>The 20% off promotion only applies to a single line item.</li>
      <li>The maximum discount is $500.</li>
      <li>Promotion does not apply to Hardcover Books</li>
      <li>Promotion cannot be used with other discounts.</li>
      <li>Promotion cannot be used more than once.</li>
      <li>
        We reserve the right to verify eligibility and adjust your order total even after you&apos;ve placed
        your order.
      </li>
    </ul>
  </div>
);

export default DiscountToolTipContent;
