import * as React from 'react';
import Grid from 'styleguide/components/Grid/Grid';
import { H2, H3 } from 'styleguide/components/Heading';
import InfoCards from 'styleguide/components/InfoCards/InfoCards';
import { Info } from 'styleguide/components/InfoCards/InfoCard/types';
import Wrapper from 'styleguide/components/Wrapper/Wrapper';

interface Props {
  cards: Info[];
  brandHeading?: string;
  brandTagline?: string;
}

const Brands = ({ cards, brandHeading, brandTagline }: Props) => (
  <Wrapper>
    {!!brandHeading ||
      (!!brandTagline && (
        <Grid.Container>
          <Grid>
            <Grid.Row>
              <Grid.Col>
                {!!brandHeading && (
                  <H2 className="mt-6 mb-4 !text-4xl text-center leading-none tracking-custom sm:!text-5xl sm:mb-0">
                    {brandHeading}
                  </H2>
                )}
                {!!brandTagline && (
                  <H3 className="text-center !m-4 !font-hvLight !text-lg sm:!text-2xl">{brandTagline}</H3>
                )}
              </Grid.Col>
            </Grid.Row>
          </Grid>
        </Grid.Container>
      ))}
    <InfoCards infoCards={cards} iconSize="md" />
  </Wrapper>
);

export default Brands;
