import * as React from 'react';
import cn from 'classnames';
import Grid from 'styleguide/components/Grid/Grid';
import ButtonAsNativeLink from 'styleguide/components/Button/ButtonAsNativeLink';

interface Props {
  title: React.ReactNode;
  description: React.ReactNode;
  buttonText?: string;
  descriptionClass?: string;
  containerClass?: string;
  titleClass?: string;
  descriptionWrapperClass?: string;
  imageColumnClass?: string;
  heroButton?: string;
  HeroImage?: string;
  buttonLink: string;
}
const TwoColumnHero = ({
  title,
  description,
  buttonText,
  descriptionClass,
  containerClass,
  titleClass,
  descriptionWrapperClass,
  imageColumnClass,
  heroButton,
  HeroImage,
  buttonLink,
}: Props) => (
  <Grid.Container className={cn('bg-blue min-h-[200px] md:min-h-[500px]', containerClass)}>
    <Grid>
      <Grid.Row className="md:bg-no-repeat md:bg-contain md:mb-0">
        <Grid.Col sm={12} md={6} className="bg-blue min-h-[200px] py-5 md:min-h-[500px] md:pt-12 md:pb-5">
          <div className={cn('-md:px-4', descriptionWrapperClass)}>
            <h1
              className={cn(
                'text-white font-hvBold mx-auto text-3xl md:text-5xl lg:text-6xl md:leading-none',
                titleClass,
              )}
            >
              {title}
            </h1>
            <div className={cn('text-white my-3', descriptionClass)}>{description}</div>
            <ButtonAsNativeLink
              buttonType="link"
              color="orange"
              target={buttonLink}
              className={cn(heroButton)}
            >
              {buttonText}
            </ButtonAsNativeLink>
          </div>
        </Grid.Col>
        <Grid.Col
          sm={12}
          md={6}
          className={cn('bg-cover bg-no-repeat md:min-h-auto', imageColumnClass)}
          style={{
            backgroundImage: `url(${HeroImage})`,
          }}
        />
      </Grid.Row>
    </Grid>
  </Grid.Container>
);

export default TwoColumnHero;
