import * as React from 'react';
import cn from 'classnames';
import css from './NavigationCard.scss';
import Image from '../Image/Image';
import Span from '../Span/Span';
import Link from 'styleguide/components/Links/Link';

export interface NavigationCard {
  image: string;
  alt: string;
  title: string;
  url: string;
  className?: string;
  onMouseOver?: () => void;
  disabled?: boolean;
  titleClassName?: string;
  animateHover?: boolean;
  dropShadow?: boolean;
}

const ProductCard = ({
  disabled = false,
  dropShadow = true,
  animateHover = true,
  ...props
}: NavigationCard) => (
  <div
    className={cn(css.productCard, { [css[`productCard--shadow`]]: !!dropShadow }, props.className)}
    onMouseOver={props.onMouseOver}
    onFocus={props.onMouseOver}
  >
    <div className="color-white absolute inset-2 text-center">
      <Link to={props.url} color="black" underline="none" className={css.link} disabled={disabled}>
        <div className={css.imageWrapper}>
          <Image
            url={props.image}
            title={props.title}
            alt={props.alt}
            className={cn(css.image, { [css[`image--animate`]]: !!animateHover })}
          />
        </div>
        <Span className={cn(css.title, props.titleClassName)}>{props.title}</Span>
      </Link>
    </div>
  </div>
);

export default ProductCard;
