import { Info } from 'styleguide/components/InfoCards/InfoCard/types';

import brandedPortals from 'assets/images/franchises/branded-portals.png';
import consistentColor from 'assets/images/franchises/consistent-color.png';
import betterPrices from 'assets/images/franchises/better-prices.png';
import growFaster from 'assets/images/franchises/grow-faster.png';

import {
  IconPriceTag,
  IconProfessionalDesignHelp,
  IconPeaceOfMind,
  IconFastTurnarounds,
} from 'styleguide/icons';

const icons = [brandedPortals, consistentColor, betterPrices, growFaster];

const titles = ['Branded Portals', 'Consistent Color', 'Better Prices', 'Grow Faster'];

const contents = [
  'Integrate your brand colors and logo into your custom portal.',
  'Everything is produced under one-roof. With G7 print certification, your brand colors will stay consistent.',
  'When your print spend is spread across different vendors, you’ll lose out on negotiated prices.',
  'Empower your franchisees to market themselves with print and promo items.',
];

export const cards: Info[] = [
  { title: titles[0], image: icons[0], content: contents[0] },
  { title: titles[1], image: icons[1], content: contents[1] },
  { title: titles[2], image: icons[2], content: contents[2] },
  { title: titles[3], image: icons[3], content: contents[3] },
];

const insightsIcons = [IconProfessionalDesignHelp, IconFastTurnarounds, IconPriceTag, IconPeaceOfMind];

const insightsTitles = ['Professional Design Help', 'Rush Turnarounds', 'Price Match', 'Peace of Mind'];
// const insightsTitles = ['Guaranteed Delivery Dates', 'Rush Turnarounds', 'Price Match', 'Peace of Mind'];

const insightsContents = [
  'Our team will make sure your files are print-ready, and can even assist with larger design projects.',
  // 'No more missed deadlines. Your products always delivered by the date promised. Always.',
  'Need your order fast? With overnight production and custom shipping options, nobody delivers faster.',
  'Ordering beautiful prints online at cheap prices has never been easier.',
  'We check every order for that perfect look and feel. Trust your brand to our team of professional designers and printers.',
];

export const insightsCards = [
  {
    title: insightsTitles[0],
    icon: insightsIcons[0],
    content: insightsContents[0],
    cardClassNames: {
      contentClasses: 'text-left',
    },
  },
  {
    title: insightsTitles[1],
    icon: insightsIcons[1],
    content: insightsContents[1],
    cardClassNames: {
      contentClasses: '',
    },
  },
  {
    title: insightsTitles[2],
    icon: insightsIcons[2],
    content: insightsContents[2],
    cardClassNames: {
      contentClasses: 'text-left',
    },
  },
  {
    title: insightsTitles[3],
    icon: insightsIcons[3],
    content: insightsContents[3],
    cardClassNames: {
      contentClasses: 'text-left',
    },
  },
];
