import * as React from 'react';
import cn from 'classnames';
import InfoCard from 'styleguide/components/InfoCards/InfoCard/InfoCard';
import { Info } from 'styleguide/components/InfoCards/InfoCard/types';
import { UiSize } from 'styleguide/styles/sizes';

interface Props {
  infoCards: Info[];
  iconSize?: UiSize;
  grid?: boolean;
  alignment?: 'left' | 'center';
}

const InfoCards = ({ infoCards, grid, alignment, ...props }: Props) => (
  <div className="flex justify-center bg-shades-0 py-8">
    <div
      className={cn(
        'grid w-full grid-cols-1 gap-x-8  gap-y-14 sm:grid-cols-2 xl:grid-cols-4',
        grid ? 'sm:!grid-cols-2 md:!grid-cols-3  xl:px-16' : 'md:grid-cols-2',
      )}
    >
      {infoCards.map((info: Info, i: number) => (
        <InfoCard
          key={i}
          icon={info.icon}
          title={info.title}
          content={info.content}
          subcontent={info.subcontent}
          iconSize={props.iconSize}
          grid={grid}
          image={info.image}
          cardClassNames={info.cardClassNames}
          alignment={alignment}
        />
      ))}
    </div>
  </div>
);

InfoCards.Card = InfoCard;

export default InfoCards;
