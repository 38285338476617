import * as React from 'react';
import cn from 'classnames';

const Wrapper = ({
  className,
  style,
  children,
  ...otherProps
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => (
  <div
    className={cn('px-4 md:px-8 3xl:mx-auto 3xl:max-w-base 3xl:px-0', className)}
    style={style}
    {...otherProps}
  >
    {children}
  </div>
);

export default Wrapper;
