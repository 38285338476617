import * as React from 'react';
import Grid from 'styleguide/components/Grid/Grid';
import { H2 } from 'styleguide/components/Heading';
import AppContext from 'contexts/AppContext/AppContext';
import ProductCard from 'styleguide/components/NavigationCard/NavigationCard';

import marketing from 'assets/images/marketing.png';
import shipping from 'assets/images/shipping-boxes.png';

interface Props {
  permalinks: string[];
  heading?: string;
  nameOverrides?: string[];
  linkOverrides?: string[];
}

const FranchiseProducts = ({ permalinks, heading, nameOverrides, linkOverrides }: Props) => {
  const appContext = React.useContext(AppContext);
  const taxonomy = appContext.store.taxonomies.find(elem => elem.name === 'Products');
  const franchiseTaxons = taxonomy.taxons.filter(taxon => permalinks.indexOf(taxon.pagePath) > -1);

  const getProductImage = elem => {
    let image;
    if (elem.name === 'Rack Cards') {
      image = marketing;
    } else if (elem.name === 'Shipping Boxes') {
      image = shipping;
    } else {
      image = elem.image.url;
    }
    return image;
  };

  return (
    <Grid.Container>
      <Grid>
        {!!heading && (
          <Grid.Row>
            <Grid.Col md={1} hiddenSm="down" />
            <Grid.Col sm={12} md={10}>
              <H2 className="text-center leading-tight !text-3xl m-0 sm:!text-5xl sm:mt-6">{heading}</H2>
            </Grid.Col>
            <Grid.Col md={1} hiddenSm="down" />
          </Grid.Row>
        )}
        <Grid.Row className="mt-10 sm:mt-12">
          <Grid.Col md={1} hiddenSm="down" />
          <Grid.Col sm={12} md={10} className="!flex !flex-row !flex-wrap !justify-around my-2">
            {franchiseTaxons.map((elem, i) => (
              <ProductCard
                key={i}
                alt={elem.image.alt}
                image={getProductImage(elem)}
                title={!!nameOverrides && !!nameOverrides[i] ? nameOverrides[i] : elem.name}
                url={!!linkOverrides && !!linkOverrides[i] ? linkOverrides[i] : '#'}
                disabled={!(!!linkOverrides && !!linkOverrides[i])}
                className="basis-[48%] sm:basis-[21%]"
                titleClassName="-xl:text-sm -lg:text-xs"
                animateHover={false}
                dropShadow={false}
              />
            ))}
          </Grid.Col>
          <Grid.Col md={1} hiddenSm="down" />
        </Grid.Row>
      </Grid>
    </Grid.Container>
  );
};

export default FranchiseProducts;
