import * as React from 'react';
import Meta from 'styleguide/components/Meta/Meta';
import TwoColumnHero from 'styleguide/components/TwoColumnHero/TwoColumnHero';
import HtmlContent from 'styleguide/components/HtmlContent/HtmlContent';
import FranchiseProducts from 'bundles/App/pages/service/FranchiseSolutions/FranchiseProducts/FranchiseProducts';
import {
  insightsLinks,
  insightsNames,
  insightsPermalinks,
} from 'bundles/App/pages/service/FranchiseSolutions/FranchiseProducts/types';
import Brands from 'bundles/App/pages/service/FranchiseSolutions/Brands/Brands';
import { insightsCards } from 'bundles/App/pages/service/FranchiseSolutions/Brands/types';
import Grid from 'styleguide/components/Grid/Grid';
import Footer from 'styleguide/components/Footer/Footer';
import ButtonAsNativeLink from 'styleguide/components/Button/ButtonAsNativeLink';
import Reviews from 'styleguide/components/Reviews/Reviews';
import img from 'assets/images/business-cards-falling.png';
import { Tooltip } from 'styleguide/components/Tooltip/Tooltip';
import ToolTipContent from 'styleguide/components/DiscountTooltipContent/DiscountTooltipContent';
import { IconTooltipInfo } from 'styleguide/icons';

const Insights = () => (
  <>
    <Meta
      title="Insights | Printivity"
      description={
        'Printivity Insights teaches, shares, and informs about the latest trends and classic approaches of print, design, and growing your business! Visit and learn today!"'
      }
      canonical="https://www.printivity.com/insights"
    />
    <TwoColumnHero
      descriptionWrapperClass="px-[15%] text-sm md:text-lg"
      title="Get up to 20% off your first order"
      descriptionClass="text-sm lg:text-lg"
      description={
        <div className="flex">
          <HtmlContent
            content={
              '<div><div><p>' +
              'Apply promo code <strong>INSIGHTS20</strong>' +
              '</p></div>' +
              '<div><strong>1</strong> Register an account</div>' +
              '<div><strong>2</strong> Log into your account</div>' +
              '<div><strong>3</strong> Enter the promotion code in your shopping cart</div>' +
              '</div>'
            }
          />
          <Tooltip
            placement="bottom"
            content={<ToolTipContent />}
            withArrow
            className="!z-100 sm:w-max max-w-[500px] rounded-lg bg-shades-0 px-5 py-4 text-sm shadow-xl !text-default"
            action="click"
            renderOpener={tooltipProps => (
              <div {...tooltipProps} className="cursor-pointer mt-4 -ml-24 md:-ml-28 h-6">
                <IconTooltipInfo color="white" size="sm" fill="none" />
              </div>
            )}
          />
        </div>
      }
      buttonLink="/sign-up"
      HeroImage={img}
      buttonText="Sign Up Now - Save 20%"
      imageColumnClass="bg-blue bg-center"
    />
    <FranchiseProducts
      permalinks={insightsPermalinks}
      linkOverrides={insightsLinks}
      nameOverrides={insightsNames}
    />
    <Grid.Container>
      <Grid>
        <Grid.Row>
          <Grid.Col className="text-center my-8">
            <ButtonAsNativeLink buttonType="link" target="/products" color="orange">
              See More Products
            </ButtonAsNativeLink>
          </Grid.Col>
        </Grid.Row>
      </Grid>
    </Grid.Container>
    <Brands cards={insightsCards} />
    <Reviews />
    <Footer />
  </>
);

export default Insights;
